import Vue from 'vue'
import Vuex from 'vuex'
// 挂载vuex
Vue.use(Vuex)

// 创建vuex对象并向外暴露
export default new Vuex.Store({
    // 全局属性变量
    state: {
        defaultPageSize: window.localStorage.getItem("defaultPageSize") || '50',
        ScreenWidth:"",
        ScreenHeight:"",
        isSuperAdmin: window.localStorage.getItem("isSuperAdmin"),
        showBadge:false,
        orderPath:false,
        refreshOrder:false
    },
    // 全局同步方法, 调用方法,this.$store.commit("xxx")
    mutations: {
        changedefaultPageSize(state, defaultPageSize) {
            state.defaultPageSize = defaultPageSize
        },
        changeScreenWidth(state, ScreenWidth) {
            state.ScreenWidth = ScreenWidth
        },
        changeScreenHeight(state, ScreenHeight) {
            state.ScreenHeight = ScreenHeight
        },
        changeisSuperAdmin(state, isSuperAdmin) {
            state.isSuperAdmin = isSuperAdmin
        },
        changeshowBadge(state, showBadge) {
            state.showBadge = showBadge
        },
        changeorderPath(state, orderPath) {
            state.orderPath = orderPath
        },
        changerefreshOrder(state, refreshOrder) {
            state.refreshOrder = refreshOrder
        },
    },
    // 异步方法 调用方法,this.$store.dispatch("xxx") 
    actions: {
    },
    // Vuex属性计算,在视图里面当变量使用
    getters: {
    },
    // 模块化注册
    modules: {
    }
})