<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
// 1.引入
import zhCn from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      // 2.赋值
      locale: zhCn,
    };
  },
};
</script>

<style>
</style>
