import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
  mode: "history",
  base: "/",//process.env.BASE_+URL
  routes: [
    {
      path: '/index',
      component: () => import('@/view/indexPage'), 
    },
    {
      path: '/login',
      component: () => import('@/view/Login'),
    },
    {
      path: '/h5/notice',
      component: () => import('@/view/h5/notice.vue'),
    },
    {
      path: '/h5/treaty',
      component: () => import('@/view/h5/treaty.vue'),
    },
    {
      path: '/h5/insure',
      component: () => import('@/view/h5/insure.vue'),
    },
    {
      path: '/h5/riderTreaty',
      component: () => import('@/view//h5/riderTreaty.vue'),
    },
    {
      path: '/h5/userTreaty',
      component: () => import('@/view//h5/userTreaty.vue'),
    },
    {
      path: '/empower',
      component: () => import('@/view/empower.vue'),
    },
    {
      path: '/',
      component: () => import('@/view/index.vue'),
      redirect: '/index',
      children: [
        // {
        //   path:"Order",
        //   component:()=>import('@/view/order')
        // },
        {
          path: 'Statistics',
          component: () => import('@/view/statistics/index.vue'),
          children:[
            {
              path: 'Report',
              component: () => import('@/view/statistics/report.vue'),
            },
            {
              path: 'Shop',
              component: () => import('@/view/statistics/shop.vue'),
            },
            {
              path: 'Rider',
              component: () => import('@/view/statistics/rider.vue'),
            },
          ]
        },
        {
          path: 'Order',
          component: () => import('@/view/order/index.vue'),
          children:[
            {
              path: 'Map',
              component: () => import('@/view/order/map.vue'),
            },
            {
              path: 'List',
              component: () => import('@/view/order/list.vue'),
            },
          ]
        },
        {
          path:"Premium",
          component:()=>import('@/view/premium')
        },
        {
          path: 'User',
          component: () => import('@/view/user/index.vue'),
          children:[
            {
              path: 'Check',
              component: () => import('@/view/user/check.vue'),
            },
            {
              path: 'List',
              component: () => import('@/view/user/list.vue'),
            },
            {
              path: 'userPricePlan',
              component: () => import('@/view/user/pricePlan.vue'),
            },
            {
              path: 'riderPricePlan',
              component: () => import('@/view/user/riderpricePlan.vue'),
            },
            {
              path: 'FreightPlan',
              component: () => import('@/view/user/freightPlan.vue'),
            },
          ]
        },
        {
          path: 'Rider',
          component: () => import('@/view/horseMan/index.vue'),
          children:[
            {
              path: 'Check',
              component: () => import('@/view/horseMan/check.vue'),
            },
            {
              path: 'Health',
              component: () => import('@/view/horseMan/check1.vue'),
            },
            {
              path: 'List',
              component: () => import('@/view/horseMan/list.vue'),
            },
            {
              path: 'Insure',
              component: () => import('@/view/horseMan/insure.vue'),
            },
          ]
        },
        {
          path: 'Marketing',
          component: () => import('@/view/marketing/index.vue'),
          children:[
            {
              path: 'Coupon',
              component: () => import('@/view/marketing/coupon.vue'),
            },
            {
              path: 'Charge',
              component: () => import('@/view/marketing/charge.vue'),
            },
          ]
        },
        {
          path: 'Operation',
          component: () => import('@/view/operation/index.vue'),
          children:[
            {
              path: 'Area',
              component: () => import('@/view/operation/area.vue'),
            },
            {
              path: 'Category',
              component: () => import('@/view/operation/category.vue'),
            },
            {
              path: 'DeliveryTime',
              component: () => import('@/view/operation/deliveryTime.vue'),
            },
            {
              path: 'Notice',
              component: () => import('@/view/operation/notice.vue'),
            },
            {
              path: 'Other',
              component: () => import('@/view/operation/otherSet.vue'),
            },
          ]
        },
        {
          path: 'Finance',
          component: () => import('@/view/finance/index.vue'),
          children:[
            {
              path: 'Fund',
              component: () => import('@/view/finance/fund.vue'),
            },
            {
              path: 'RiderWithdrawal',
              component: () => import('@/view/finance/riderWithdrawal.vue'),
            },
          ]
        },
        {
          path: 'Account',
          component: () => import('@/view/account/index.vue'),
          children:[
            {
              path: 'Sub',
              component: () => import('@/view/account/sub.vue'),
            },
            {
              path: 'Role',
              component: () => import('@/view/account/role.vue'),
            },
          ]
        },
        {
          path: 'System/Agent',
          component: () => import('@/view/system/agent.vue'),
        },
        {
          path: 'System/User',
          component: () => import('@/view/system/user.vue'),
        },
        {
          path: 'System/Withdrawal',
          component: () => import('@/view/system/withdrawal.vue'),
        },
      ]
    },
    // {
    //   path: '*',
    //   redirect: '/login',
    // },
    {
      path: '*',
      redirect: '/index',
    }
  ]
})
router.beforeEach((to, from, next) => {
  // console.log(to, from, next)
  if (to.path === "/login"||to.path=="/empower") {
    return next()
  }
  if (to.path === "/h5/notice"||to.path === "/h5/treaty"||to.path === "/h5/insure"||to.path === "/h5/riderTreaty"||to.path === "/h5/userTreaty"||to.path === "/index") {
    return next()
  }
  if (to.path === "/Order/List") {
    return next()
  }
  const tokenStr = window.localStorage.getItem("Authorization")
  if (!tokenStr) {
    return next("/login")
  }
  next()
})
export default router